document.addEventListener('turbolinks:load', function () {
  const mobileMenu = document.getElementById('mobileMenu');
  const openMobileMenuButton = document.getElementById('openMobileMenu');
  const closeMobileMenuButtons = document.querySelectorAll('#mobileMenu button');
  const metaTag = document.querySelector('meta[name="csrf-token"][data-csrf-token]');

  if (metaTag && !window.csrfToken) {
    window.csrfToken = metaTag.content;
  }

  function openMobileMenu() {
    mobileMenu.classList.add('visible');
  }

  function closeMobileMenu() {
    mobileMenu.classList.remove('visible');
  }

  function handleMenuItemClick(event) {
    event.stopPropagation();
    closeMobileMenu();
  }

  function logout() {
    const form = document.createElement('form');
    form.method = 'post';
    form.action = window.logoutPath;
    form.style.display = 'none';

    const csrfTokenInput = document.createElement('input');
    csrfTokenInput.type = 'hidden';
    csrfTokenInput.name = 'authenticity_token';
    csrfTokenInput.value = window.csrfToken;

    const methodInput = document.createElement('input');
    methodInput.type = 'hidden';
    methodInput.name = '_method';
    methodInput.value = 'delete';

    form.appendChild(csrfTokenInput);
    form.appendChild(methodInput);

    document.body.appendChild(form);
    form.submit();
  }
  if(openMobileMenuButton && mobileMenu){
    openMobileMenuButton.addEventListener('click', openMobileMenu);
    mobileMenu.addEventListener('click', closeMobileMenu);
  }
  closeMobileMenuButtons.forEach(button => {
    button.addEventListener('click', handleMenuItemClick);
  });

  window.logout = logout;
});
